export function useTrackEvent() {
  const gtm = useGtm()
  function triggerEvent(label: string) {
    gtm?.trackEvent({
      event: 'form',
      action: 'click',
      label,
    })
  }
  function pushData(event?: string, additionalOptions?: object) {
    gtm?.push({
      event: event || 'intercept',
      ...additionalOptions,
    })
  }
  return {
    triggerEvent,
    pushData,
  }
}
